import { classList } from '~/utilities/css';

type Props = {
	message?: string;
	position?: 'top' | 'center',
};

/**
 * To use `OverlayContent` you need a mechanism like `useState()` to add/remove
 * the element based on some state. Additionally, you need to make sure that the
 * content that you want to overlay is marked `position: relative` via class or
 * some other method (just basic absolute positioning stuff i.e., absolute positioned
 * elements are relative the closest parent with said property).
 */
function OverlayContent({ message = 'One moment...', position = 'center' }: Props) {
	return (
		<p className={classList([
			'uic-absolute uic-inset-[0] uic-z-10',
			'uic-m-auto uic-w-full uic-h-full',
			'uic-bg-white/95 uic-font-quicksand uic-font-semibold',
			'uic-flex uic-flex-col uic-items-center',
			`${position === 'center' ? 'uic-justify-center' : ''}`,
			`${position === 'top' ? 'uic-justify-start uic-pt-30' : ''}`,
		])}
		>
			<span className="uic-text-sm">{message}</span>
		</p>
	);
}

export { OverlayContent as default, OverlayContent };
